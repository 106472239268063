import React from "react"
import { Link, graphql } from "gatsby"
import Seo from "../../components/seo";
import BaseLayout from "../../components/layout"
import HorizontalRule from "../../components/horizontal-rule";
import BreadcrumbTrail from "../../components/breadcrumb-trail";
import kebabCase from "lodash/kebabCase";


export default class BibliographyPage extends React.Component  {
  render() {
    const articles = this.props.data.allMdx.nodes;
    const numArticles = articles.length;
    const allCollections = this.props.data.allCollections.nodes;

    const publishedBooks = [];
    const informalCollections = [];
    for (let collection of allCollections) {
      if (collection.purchase.display == true) {
        publishedBooks.push(collection)
      } else {
        informalCollections.push(collection)
      }
    }

    let breadcrumbList = [
      [
        {
          title: 'Home',
          url: '/'
        },
        {
          title: 'Library',
          url: '/writing/library/'
        },
        {
          title: 'Bibliography',
        }
      ]
    ]

    return (
      <BaseLayout>
        <Seo title="Bibliography" desc="Bibliography of all written work by Zack Thoutt." pathname={"/writing/bibliography/"} breadcrumbList={breadcrumbList}></Seo>
        <div className="layout-base-w app-p-base">
          <BreadcrumbTrail breadcrumbTrail={breadcrumbList[0]}></BreadcrumbTrail>
          <div className="text-mint">
            <HorizontalRule ruleType="1"></HorizontalRule>
            <h1 className="text-header-1 w-full">Bibliography</h1>
          </div>

          <div className="mt-20">
            <h6 className="text-subheader-1 mb-12">Published Books</h6>
            {publishedBooks.map((item, index) => (
              <p className="text-body-2 mb-12" key={index}>{(publishedBooks.length - index)}. <Link to={`/writing/library/${kebabCase(item.title)}`} className="text-link">Thoutt, Zack. <i>{item.title}</i>. Self-published, {item.published_date}.</Link></p>
            ))}
          </div>

          <div className="mt-20">
            <h6 className="text-subheader-1 mb-12">Informal Online Collections</h6>
            {informalCollections.map((item, index) => (
              <p className="text-body-2 mb-12" key={index}>{(informalCollections.length - index)}. <Link to={`/writing/library/${kebabCase(item.title)}`} className="text-link">Thoutt, Zack. <i>{item.title}</i>. zackthoutt.com, {item.published_date}.</Link></p>
            ))}
          </div>

          <div className="mt-20">
            <h6 className="text-subheader-1 mb-12">Online Chapters</h6>
            {articles.map((item, index) => (
              <p className="text-body-2 mb-12" key={index}>{(numArticles - index)}. <Link to={`/${item.slug}`} className="text-link">"{item.frontmatter.title}," Chapter {item.frontmatter.chapter} of <i>{item.frontmatter.collection}</i>. zackthoutt.com, {item.frontmatter.formatted_published_date}.</Link></p>
            ))}
          </div>
        </div>
      </BaseLayout>
    )
  }
}


export const pageQuery = graphql`
  query Bibliography {
    site {
      siteMetadata {
        title
      }
    }

    allMdx(
      sort: { order: DESC, fields: [frontmatter___published_date] },
      filter: { frontmatter: { published: { eq: true }, record_type: { eq: "story" } } }
    ) {
      nodes {
        ...ArticleSnippet
      }
    }

    allCollections(
      sort: { order: ASC, fields: [sort_order] }
    ) {
      nodes {
        ...CollectionFields
      }
    }
  }
`